import React, { useMemo, useEffect } from "react"
import { useTable, useSortBy } from "react-table"

const ForecastDataTable = ({ data, selectedProjects, onCsvDataReady, businessDaysInPeriod }) => {
  const formatCurrency = value => {
    const numericValue = parseFloat(value)
    if (isNaN(numericValue)) return "-"
    return `$${numericValue.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  }

  const formatPercentage = value => {
    const numericValue = parseFloat(value)
    return isNaN(numericValue) ? "-" : `${numericValue.toFixed(2)}%`
  }

  const columns = useMemo(() => [
    { Header: "Client", accessor: "client" },
    { Header: "Project", accessor: "project" },
    {
      Header: "Projected Revenue",
      accessor: "projected_revenue",
      Cell: ({ value }) => formatCurrency(value),
    },
    {
      Header: "Projected Contractor Cost",
      accessor: "projected_contractor_cost",
      Cell: ({ value }) => formatCurrency(value),
    },
    {
      Header: "Percentage of Forecasted Revenue",
      accessor: "percentage_of_forecasted_revenue",
      Cell: ({ value }) => formatPercentage(value),
    },
  ], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: data.rows || [],
      initialState: {
        sortBy: [{ id: "client", desc: false }],
      },
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
  )

  const includeProject = (projectDataRow) => {
    return selectedProjects?.[projectDataRow?.id]?.selected
  }

  const calculateTotalsRow = useMemo(() => {
    const totals = {
      client: 'All',
      project: 'All',
      projected_revenue: 0,
      projected_contractor_cost: 0,
      percentage_of_forecasted_revenue: '-'
    }

    data.rows.forEach(row => {
      if (includeProject(row)) {
        totals.projected_revenue += parseFloat(row.projected_revenue)
        totals.projected_contractor_cost += parseFloat(row.projected_contractor_cost)
      }
    })

    return totals
  }, [data.rows, selectedProjects])

  const formatTotalsRow = (columns, totals) => {
    return columns.map(column => {
      const totalValue = totals[column.accessor]

      if (!totalValue) return "-"
      if (column.accessor.includes("revenue") || column.accessor.includes("cost")) {
        return formatCurrency(totalValue)
      } else if (column.accessor === "percentage_of_forecasted_revenue") {
        return formatPercentage(totalValue)
      } else {
        return totalValue.toString()
      }
    })
  }

  const renderTotalsRow = () => {
    const formattedTotals = formatTotalsRow(columns, calculateTotalsRow)

    return (
      <tr>
        {formattedTotals.map((formattedValue, index) => (
          <td key={columns[index].accessor}>
            <b>{formattedValue}</b>
          </td>
        ))}
      </tr>
    )
  }

  const csvData = useMemo(() => {
    const headers = columns.map(column => column.Header)
    const dataRows = data.rows.map(row =>
      columns.map(column => {
        const cellValue = row[column.accessor]
        if (column.accessor.includes("revenue") || column.accessor.includes("cost")) {
          return formatCurrency(cellValue)
        } else if (column.accessor === "percentage_of_forecasted_revenue") {
          return formatPercentage(cellValue)
        }
        return cellValue.toString()
      }),
    )

    const formattedTotals = formatTotalsRow(columns, calculateTotalsRow)
    return [headers, ...dataRows, formattedTotals]
  }, [data.rows, columns])

  useEffect(() => {
    onCsvDataReady(csvData)
  }, [csvData, onCsvDataReady])

  const totals = calculateTotalsRow
  const totalRevenue = totals.projected_revenue
  const totalContractorCost = totals.projected_contractor_cost
  const totalOverhead = data.daily_overhead * businessDaysInPeriod
  const totalProfit = totalRevenue - totalContractorCost - totalOverhead
  const totalMargin = totalRevenue !== 0 ? (totalProfit / totalRevenue) * 100 : 0

  return (
    <>
      <div className="totals-table mt-2">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Projected Revenue</th>
              <th>Projected Contractor Cost</th>
              <th>Projected Overhead</th>
              <th>Profit</th>
              <th>Margin</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formatCurrency(totalRevenue)}</td>
              <td>{formatCurrency(totalContractorCost)}</td>
              <td>{formatCurrency(totalOverhead)}</td>
              <td>{formatCurrency(totalProfit)}</td>
              <td>{formatPercentage(totalMargin)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <table
        {...getTableProps()}
        className="table table-striped table-bordered"
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            if (!includeProject(row.original)) return null
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            )
          })}
          {renderTotalsRow()}
        </tbody>
      </table>
    </>
  )
}

export default ForecastDataTable
