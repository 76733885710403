import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import InvoicesIndex from './InvoicesIndex'
import InvoiceShow from './InvoiceShow'

const InvoiceDashboard = ({ clients, states }) => {
  return (
    <Router>
      <Routes>
        <Route path="/invoices" element={<InvoicesIndex clients={clients} states={states} />} />
        <Route path="/invoices/:id" element={<InvoiceShow />} />
      </Routes>
    </Router>
  )
}

export default InvoiceDashboard
