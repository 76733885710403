import React, { useState } from "react"
import { format } from "date-fns"
import { useQuery } from "react-query"

const fetchInvoicingReportsData = async (page) => {
  const response = await fetch(`/invoicing_reports?page=${page}`, {
    headers: {
      Accept: "application/json",
    },
  })
  if (!response.ok) throw new Error("Network response was not ok")
  return response.json()
}

const InvoicingReportsIndex = () => {
  const [page, setPage] = useState(1)

  const { data, isLoading, error } = useQuery(
    ["invoicingReportsData", page],
    () => fetchInvoicingReportsData(page),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 15, // 15 minutes
    },
  )

  if (isLoading || !data) return <div>Loading...</div>
  if (error) return <div>An error occurred: {error.toString()}</div>

  return (
    <>
      <h1>Invoicing Reports</h1>
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col-md-12">
            <a href="/invoicing_reports/new" className="btn btn-primary">
              Create New Report
            </a>
          </div>
        </div>

        <table className="table table-striped">
          <thead>
            <tr>
              <th>Title</th>
              <th>Start Period</th>
              <th>End Period</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.invoicingReports.map((report) => (
              <tr key={report.id}>
                <td>{report.title}</td>
                <td>{format(new Date(report.start_period_at), "MM/dd/yyyy")}</td>
                <td>{format(new Date(report.end_period_at), "MM/dd/yyyy")}</td>
                <td>
                  <a
                    className="btn btn-primary"
                    href={`/invoicing_reports/${report.id}`}
                  >
                    View
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav>
          <ul className="pagination">
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                Previous
              </button>
            </li>
            <li className="page-item">
              <span className="page-link">{page}</span>
            </li>
            <li className={`page-item ${data.invoicingReports.length === 0 ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => setPage(page + 1)} disabled={data.invoicingReports.length === 0}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default InvoicingReportsIndex
