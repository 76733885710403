import React from 'react'

function CurrencyInput({ value, onChange, onBlur }) {
  const handleChange = (e) => {
    const cleanedValue = e.target.value.replace(/,/g, '');
    const decimalRegex = /^\d*\.?\d{0,2}$/;
    if (cleanedValue === '' || decimalRegex.test(cleanedValue)) {
      onChange({ target: { name: e.target.name, value: cleanedValue } });
    }
  }

  // const formatValue = (value) => {
  //   if (!value) return '';
  //   // Add commas for better readability
  //   return parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  // }

  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <span className="input-group-text">$</span>
      </div>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className="form-control"
        placeholder="0.00"
      />
    </div>
  )
}

export default CurrencyInput
