import React, { useEffect, useState } from "react"
import { useQuery } from "react-query"
import ProfitDataTable from "./ProfitDataTable"
import { CSVLink } from "react-csv"
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
} from "date-fns"
import TimeNavigation from "./TimeNavigation"

const fetchPerformanceData = async (startDate, endDate, onlyActiveProjects) => {
  const response = await fetch(
    `/performance/data?start_datetime=${startDate}&end_datetime=${endDate}&only_active_projects=${onlyActiveProjects}`,
  )
  if (!response.ok) throw new Error("Network response was not ok")
  return response.json()
}

const PerformanceDashboard = () => {
  const [activeTable, setActiveTable] = useState("project")
  const [dateRange, setDateRange] = useState("month")
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"))
  const [customStartDate, setCustomStartDate] = useState("")
  const [customEndDate, setCustomEndDate] = useState("")
  const [onlyActiveProjects, setOnlyActiveProjects] = useState(false)
  const [csvData, setCsvData] = useState([])

  // Effect hook to update startDate based on the selected dateRange
  useEffect(() => {
    switch (dateRange) {
      case "week":
        setStartDate(
          format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd"),
        )
        break
      case "month":
        setStartDate(format(startOfMonth(new Date()), "yyyy-MM-dd"))
        break
      case "quarter":
        setStartDate(format(startOfQuarter(new Date()), "yyyy-MM-dd"))
        break
      case "year":
        setStartDate(format(startOfYear(new Date()), "yyyy-MM-dd"))
        break
    }
  }, [dateRange])

  useEffect(() => {
    if (customStartDate) {
      setStartDate(
        format(new Date(`${customStartDate}T00:00:00`), "yyyy-MM-dd"),
      )
    }
  }, [customStartDate])

  // Calculate endDate based on startDate and dateRange
  const calculateEndDate = (range, start) => {
    const baseDate = new Date(`${start}T00:00:00`) // Ensure local timezone
    switch (range) {
      case "week":
        return format(endOfWeek(baseDate, { weekStartsOn: 1 }), "yyyy-MM-dd")
      case "quarter":
        return format(endOfQuarter(baseDate), "yyyy-MM-dd")
      case "year":
        return format(endOfYear(baseDate), "yyyy-MM-dd")
      case "custom":
        return customEndDate
      default:
        // "month" or any other case
        return format(endOfMonth(baseDate), "yyyy-MM-dd")
    }
  }

  const endDate = calculateEndDate(dateRange, startDate)

  const { data, isLoading, error } = useQuery(
    ["performanceData", startDate, endDate, onlyActiveProjects],
    () => fetchPerformanceData(startDate, endDate, onlyActiveProjects),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 15, // 15 minutes
    },
  )

  const handleCsvDataReady = data => {
    setCsvData(data)
  }

  if (isLoading || !data) return <div>Loading...</div>
  if (error) return <div>An error occurred: {error.toString()}</div>

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Performance</h1>

        <select
          value={dateRange}
          className="form-control w-inherit"
          onChange={e => setDateRange(e.target.value)}
        >
          <option value="month">Month</option>
          <option value="week">Week</option>
          <option value="quarter">Quarter</option>
          <option value="year">Year</option>
          <option value="custom">Custom</option>
        </select>
      </div>

      <div className="form-check my-2">
        <input
          type="checkbox"
          id="onlyActiveProjects"
          checked={onlyActiveProjects}
          onChange={e => setOnlyActiveProjects(e.currentTarget.checked)}
          className="form-check-input"
        />
        <label className="form-check-label" htmlFor="stale">
          Only Active Projects
        </label>
      </div>

      {dateRange !== "custom" && (
        <TimeNavigation
          cursor={new Date(`${startDate}T00:00:00`)}
          setDate={newDate => setStartDate(format(newDate, "yyyy-MM-dd"))}
          unit={dateRange}
        />
      )}

      {dateRange === "custom" && (
        <div className="d-flex flex-column border p-3 mb-4">
          <div className="d-flex justify-content-center">
            <div className="d-flex filters col-md-9 align-items-center">
              <>
                <input
                  type="date"
                  className="form-control w-inherit"
                  value={customStartDate}
                  onChange={e => setCustomStartDate(e.target.value)}
                />
                <b className="mr-2 ml-2">{"to "}</b>
                <input
                  type="date"
                  className="form-control w-inherit"
                  value={customEndDate}
                  onChange={e => setCustomEndDate(e.target.value)}
                />
              </>
            </div>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-between align-items-center">
        <div className="d-inline-flex w-inherit border-left border-right border-top">
          <span
            onClick={() => setActiveTable("project")}
            role="button"
            className={`p-2 border-right ${
              activeTable === "project" ? "active" : ""
            }`}
          >
            Profit by Project
          </span>
          <span
            onClick={() => setActiveTable("client")}
            role="button"
            className={`p-2 ${activeTable === "client" ? "active" : ""}`}
          >
            Profit by Client
          </span>
        </div>

        <CSVLink
          data={csvData}
          filename="performance-profit-data.csv"
          className="btn btn-primary align-self-center"
        >
          Export to CSV
        </CSVLink>
      </div>

      <div className="table-responsive-horizontal">
        {activeTable === "project" ? (
          <ProfitDataTable
            data={data.project}
            includeProject={true}
            onCsvDataReady={handleCsvDataReady}
          />
        ) : (
          <ProfitDataTable
            data={data.client}
            includeProject={false}
            onCsvDataReady={handleCsvDataReady}
          />
        )}
      </div>
    </>
  )
}

export default PerformanceDashboard
