import React, { useState } from 'react'
import { format, startOfWeek, startOfYear } from 'date-fns'
import axios from 'axios'

const InvoicingReportMutate = () => {
  const mostRecentSunday = startOfWeek(new Date(), { weekStartsOn: 0 })
  const [title, setTitle] = useState(`Week ending ${format(mostRecentSunday, 'MMMM do, yyyy')}`)
  const startOfYearDate = startOfYear(new Date())
  const [startPeriodAt, setStartPeriodAt] = useState(format(startOfYearDate, 'yyyy-MM-dd'))
  const [endPeriodAt, setEndPeriodAt] = useState(format(mostRecentSunday, 'yyyy-MM-dd'))

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!title || !startPeriodAt || !endPeriodAt) {
      alert('All fields are required')
      return
    }

    const invoicingReport = {
      title,
      start_period_at: `${startPeriodAt}T00:00:00`,
      end_period_at: `${endPeriodAt}T00:00:00`
    }

    axios.post('/invoicing_reports', { invoicing_report: invoicingReport }, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        console.log("RESPONSE: ", response)
        window.location.href = `/invoicing_reports/${response.data.id}`
      })
      .catch(error => {
        console.error('There was an error creating the invoicing report!', error)
      })
  }

  return (
    <div className="container mt-5">
        <h1>New Invoicing Report</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Title</label>
            <input
              type="text"
              className="form-control"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="startPeriodAt">Start Period</label>
            <input
              type="date"
              className="form-control"
              id="startPeriodAt"
              value={startPeriodAt}
              onChange={(e) => setStartPeriodAt(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="endPeriodAt">End Period</label>
            <input
              type="date"
              className="form-control"
              id="endPeriodAt"
              value={endPeriodAt}
              onChange={(e) => setEndPeriodAt(e.target.value)}
            />
          </div>
          <button type="submit" className="btn btn-primary">Continue</button>
        </form>
    </div>
  )
}

export default InvoicingReportMutate
