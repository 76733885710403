import React from "react"
import { ErrorBoundary } from "react-error-boundary"

import { BrowserRouter as Router } from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"

const FallbackComponent: React.FC<any> = ({ componentStack, error }) => (
  <div>
    <p>
      <strong>Oops! An error occured!</strong>
    </p>
    <p>Here’s what we know…</p>
    <p>
      <strong>Error:</strong> {error.toString()}
    </p>
    <p>
      <strong>Stacktrace:</strong> {componentStack}
    </p>
  </div>
)

export function withAppContext(Component: any) {
  return (props: any) => {
    return (
      <Router>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <Component {...props} />
          </ErrorBoundary>
        </QueryParamProvider>
      </Router>
    )
  }
}
