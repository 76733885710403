import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useQuery } from "react-query"

const fetchInvoiceData = async (id) => {
  const response = await fetch(`/invoices/${id}`, {
    headers: {
      'Accept': 'application/json'
    }
  })
  if (!response.ok) throw new Error("Network response was not ok")
  return response.json()
}

const InvoiceShow = () => {
  const { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { selectedClient, selectedState, page } = location.state || {}

  const { data, isLoading, error } = useQuery(
    ["invoiceData", id],
    () => fetchInvoiceData(id),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 15, // 15 minutes
    },
  )

  const [dueDate, setDueDate] = useState(data?.invoice?.due_date || '')
  const [expectedDate, setExpectedDate] = useState(data?.invoice?.expected_date || '')

  useEffect(() => {
    if (data) {
      setDueDate(data.invoice.due_date || '')
      setExpectedDate(data.invoice.expected_date || '')
    }
  }, [data])

  const handleBackClick = () => {
    navigate('/invoices', {
      state: { selectedClient, selectedState, page }
    })
  }

  const handleDueDateChange = async (e) => {
    const newDueDate = e.target.value
    setDueDate(newDueDate)
    await updateInvoice({ due_date: newDueDate, expected_date: expectedDate })
  }

  const handleExpectedDateChange = async (e) => {
    const newExpectedDate = e.target.value
    setExpectedDate(newExpectedDate)
    await updateInvoice({ due_date: dueDate, expected_date: newExpectedDate })
  }

  const updateInvoice = async (updatedFields) => {
    try {
      const response = await fetch(`/invoices/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          harvest_invoice: updatedFields
        })
      })
      if (!response.ok) throw new Error("Network response was not ok")
    } catch (error) {
      alert(`An error occurred: ${error.toString()}`)
    }
  }

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to permanently delete this invoice?')) {
      try {
        const response = await fetch(`/invoices/${id}`, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json'
          }
        })
        if (!response.ok) throw new Error("Network response was not ok")
        navigate('/invoices', {
          state: { selectedClient, selectedState, page }
        })
      } catch (error) {
        alert(`An error occurred: ${error.toString()}`)
      }
    }
  }

  if (isLoading || !data) return <div>Loading...</div>
  if (error) return <div>An error occurred: {error.toString()}</div>
  const invoice = data.invoice

  const formatDate = (dateString) => {
    if (!dateString) return '-'
    const date = new Date(dateString)
    return date.toLocaleDateString()
  };

  const formatCurrency = (amount) => {
    if (amount == null) return '-';
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
  };

  return (
    <div className="container">
      <button onClick={handleBackClick} className="btn btn-secondary mb-4">Back to Invoices</button>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>
          Invoice #{invoice.number}
          <a
            href={`https://tanookilabs.harvestapp.com/invoices/${invoice.harvest_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-2"
          >
            <i className="fa fa-external-link-alt" style={{ fontSize: '60%' }} />
          </a>
        </h1>
        <button onClick={handleDelete} className="btn btn-danger ml-2">Delete</button>

      </div>
      <p><strong>Client:</strong> <a href={`/harvest_clients/${invoice.harvest_client.id}`}>{invoice.harvest_client.name}</a></p>
      <p><strong>Amount:</strong> {formatCurrency(invoice.amount)}</p>
      <p><strong>State:</strong> {invoice.state}</p>
      <p><strong>Issue Date:</strong> {formatDate(invoice.issue_date)}</p>

      <div className="form-group">
        <label htmlFor="dueDate"><strong>Due Date:</strong></label>
        <input
          type="date"
          id="dueDate"
          className="form-control"
          value={dueDate}
          onChange={handleDueDateChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="expectedDate"><strong>Expected Date:</strong></label>
        <input
          type="date"
          id="expectedDate"
          className="form-control"
          value={expectedDate}
          onChange={handleExpectedDateChange}
        />
      </div>

      <hr className="my-4" />

      <h3>Line Items</h3>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Kind</th>
            <th>Description</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoice.harvest_invoice_line_items.map((item) => (
            <tr key={item.id}>
              <td>{item.kind}</td>
              <td>{item.description}</td>
              <td>{item.quantity}</td>
              <td>{formatCurrency(item.unit_price)}</td>
              <td>{formatCurrency(item.amount)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default InvoiceShow
