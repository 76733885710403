const getIframeDoc = objFrm => {
  return objFrm.contentDocument
    ? objFrm.contentDocument
    : objFrm.contentWindow.document
}

const getFrameHeight = objFrm => {
  const doc = getIframeDoc(objFrm)
  var body = doc.body
  var html = doc.documentElement
  var height = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  )

  return height
}

const setMailPreviewHeight = objFrm => {
  const doc = getIframeDoc(objFrm)
  var body = doc.body
  var innerIframe = $(body).find("iframe")
  var headerHeight = $(body)
    .find("header")
    .height()
  var innerHeight = getFrameHeight(innerIframe.get(0))

  height = headerHeight + innerHeight + 30
  objFrm.style.height = height + "px"
}

const setMailPreviewStyle = objFrm => {
  // Apply a couple simple default styles so you can see what it looks like in gmail
  const doc = getIframeDoc(objFrm)
  var body = doc.body
  var innerIframe = $(body)
    .find("iframe")
    .get(0)
  var innerIframeDoc = getIframeDoc(innerIframe)
  var innerBody = $(innerIframeDoc.body)
  innerBody.css("font", "small/1.5 Arial,Helvetica,sans-serif")
}

window.onLoadMailPreviewIframe = objFrm => {
  setMailPreviewHeight(objFrm)
  setMailPreviewStyle(objFrm)
}
