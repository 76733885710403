import React from 'react'
import { DisclosureButton } from "@reach/disclosure"
import { 
  subWeeks, addWeeks, subMonths, addMonths, subYears, addYears,
  startOfWeek, endOfWeek, startOfMonth, endOfMonth,
  startOfQuarter, endOfQuarter, startOfYear, endOfYear, format 
} from "date-fns"
import invariant from "tiny-invariant"

// Utility function to capitalize the first letter of a string
const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1)

const calculatePeriod = (unit, date) => {
  switch (unit) {
    case "week":
      return { start: startOfWeek(date, { weekStartsOn: 1 }), end: endOfWeek(date, { weekStartsOn: 1 }) }
    case "month":
      return { start: startOfMonth(date), end: endOfMonth(date) }
    case "quarter":
      return { start: startOfQuarter(date), end: endOfQuarter(date) }
    case "year":
      return { start: startOfYear(date), end: endOfYear(date) }
    default:
      throw new Error(`Unsupported unit: ${unit}`)
  }
};

const TimeNavigation = ({ cursor, setDate, unit }) => {
  const { start, end } = calculatePeriod(unit, cursor)

  const resetToCurrent = () => {
    const current = calculatePeriod(unit, new Date())
    setDate(current.start)
  }

  const moveDate = (direction) => {
    invariant(cursor);
    switch (unit) {
      case "week":
        setDate(direction === 'back' ? subWeeks(cursor, 1) : addWeeks(cursor, 1))
        break;
      case "month":
        setDate(direction === 'back' ? subMonths(cursor, 1) : addMonths(cursor, 1))
        break;
      case "quarter":
        setDate(direction === 'back' ? subMonths(cursor, 3) : addMonths(cursor, 3))
        break;
      case "year":
        setDate(direction === 'back' ? subYears(cursor, 1) : addYears(cursor, 1))
        break;
      default:
        throw new Error(`Unsupported unit: ${unit}`)
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className="btn-group">
        <button
          type="button"
          onClick={() => moveDate('back')}
          className="btn btn-outline-secondary btn-sm"
        >
          ←
        </button>
        <DisclosureButton
          className="btn btn-outline-secondary btn-sm"
          aria-label="Advanced Options"
          onClick={resetToCurrent}
        >
          {`This ${capitalize(unit)}`}
        </DisclosureButton>
        <button
          type="button"
          onClick={() => moveDate('forward')}
          className="btn btn-outline-secondary btn-sm"
        >
          →
        </button>
      </div>
      <div className="ml-2 mr-auto">
        {`${capitalize(unit)} ${format(start, "MMM d")}–${format(end, "MMM d, yyyy")}`}
      </div>
    </div>
  );
};

export default TimeNavigation