export async function request({
  path,
  params,
}: {
  path: string
  params?: { [x: string]: string | number | Date }
}) {
  const csrfMeta = document.querySelector("meta[name=csrf-token]")
  const csrf = csrfMeta instanceof HTMLMetaElement ? csrfMeta.content : null

  const headers: HeadersInit = new Headers()
  headers.set("Content-Type", "application/json")
  headers.set("Accept", "application/json")
  if (csrf) headers.set("X-CSRF-Token", csrf)

  const searchParams = new URLSearchParams()
  if (params)
    Object.entries(params).forEach(([k, v]) => searchParams.set(k, `${v}`))
  const url = `${path}${params ? `?${searchParams}` : ""}`

  const response = await fetch(url, {
    headers: headers,
    credentials: "same-origin",
  })

  if (!response.ok) {
    throw new Error(`Response not ok (${response.status})`)
  }

  return await response.json()
}

export async function queryRequest(
  _key: string,
  ...args: Parameters<typeof request>
) {
  return request(...args)
}
