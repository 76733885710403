import React, { useState, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import { CSVLink } from "react-csv"
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  eachDayOfInterval,
  isWeekend
} from "date-fns"
import TimeNavigation from "./TimeNavigation"
import ForecastDataTable from "./ForecastDataTable"
import ForecastProjectFilter from "./ForecastProjectFilter"

const fetchForecastData = async (startDate, endDate, includeProspects) => {
  const response = await fetch(
    `/forecast/data?start_datetime=${startDate}&end_datetime=${endDate}&include_prospects=${includeProspects}`,
  )
  if (!response.ok) throw new Error("Network response was not ok")
  return response.json()
}

const ForecastDashboard = () => {
  const [dateRange, setDateRange] = useState("month")
  const [csvData, setCsvData] = useState([])
  const [includeProspects, setIncludeProspects] = useState(true)
  const [customStartDate, setCustomStartDate] = useState("")
  const [customEndDate, setCustomEndDate] = useState("")
  const [selectedProjects, setSelectedProjects] = useState(null)

  const startDate = useMemo(() => {
    let cursor = new Date()
    if (customStartDate) {
      cursor = new Date(`${customStartDate}T00:00:00`)
    }

    switch (dateRange) {
      case "week":
        return format(startOfWeek(cursor, { weekStartsOn: 1 }), "yyyy-MM-dd")
      case "month":
        return format(startOfMonth(cursor), "yyyy-MM-dd")
      case "quarter":
        return format(startOfQuarter(cursor), "yyyy-MM-dd")
      case "year":
        return format(startOfYear(cursor), "yyyy-MM-dd")
      case "custom":
        return customStartDate
      default:
        return format(startOfMonth(cursor), "yyyy-MM-dd")
    }
  }, [dateRange, customStartDate])

  const endDate = useMemo(() => {
    const baseDate = new Date(`${startDate}T00:00:00`)

    switch (dateRange) {
      case "week":
        return format(endOfWeek(baseDate, { weekStartsOn: 1 }), "yyyy-MM-dd")
      case "quarter":
        return format(endOfQuarter(baseDate), "yyyy-MM-dd")
      case "year":
        return format(endOfYear(baseDate), "yyyy-MM-dd")
      case "custom":
        return customEndDate
      default:
        return format(endOfMonth(baseDate), "yyyy-MM-dd")
    }
  }, [dateRange, startDate, customEndDate])

  const { data, isLoading, error } = useQuery(
    ["forecastData", startDate, endDate, includeProspects],
    () => fetchForecastData(startDate, endDate, includeProspects),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 15, // 15 minutes
    },
  )

  useEffect(() => {
    if (data?.rows) {
      const projects = data.rows.map(row => ({
        id: row.id,
        name: row.project
      }))

      setSelectedProjects(prevSelectedProjects => {
        const previousProjects = prevSelectedProjects || {}

        return projects.reduce((acc, project) => {
          acc[project.id] = {
            name: project.name,
            selected: previousProjects[project.id]?.selected ?? true
          }
          return acc
        }, {})
      })
    }
  }, [data])

  const businessDaysInPeriod = useMemo(() => {
    if (startDate && endDate) {
      const startDateObj = new Date(`${startDate}T00:00:00`)
      const endDateObj = new Date(`${endDate}T00:00:00`)

      const allDays = eachDayOfInterval({ start: startDateObj, end: endDateObj })
      const businessDays = allDays.filter(day => !isWeekend(day))

      return businessDays.length
    } else {
      return 0
    }
  }, [endDate])

  const handleSelectionChange = projectId => {
    setSelectedProjects((prevSelectedProjects) => ({
      ...prevSelectedProjects,
      [projectId]: {
        name: prevSelectedProjects[projectId]["name"],
        selected: !prevSelectedProjects[projectId]["selected"]
      }
    }))
  }

  const handleCsvDataReady = data => {
    setCsvData(data)
  }

  if (isLoading || !data) return <div>Loading...</div>
  if (error) return <div>An error occurred: {error.toString()}</div>

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Forecast</h1>
        <div className="d-flex">
          {selectedProjects &&
            <ForecastProjectFilter projects={selectedProjects} onSelectionChange={handleSelectionChange} />
          }
          <select
            value={dateRange}
            className="form-control w-inherit ml-2"
            onChange={e => setDateRange(e.target.value)}
          >
            <option value="month">Month</option>
            <option value="week">Week</option>
            <option value="quarter">Quarter</option>
            <option value="year">Year</option>
            <option value="custom">Custom</option>
          </select>
        </div>
      </div>

      <div className="form-check my-2">
        <input
          type="checkbox"
          id="includeProspects"
          checked={includeProspects}
          onChange={e => setIncludeProspects(e.currentTarget.checked)}
          className="form-check-input"
        />
        <label className="form-check-label" htmlFor="stale">
          Include Prospects
        </label>
      </div>

      {dateRange !== "custom" && (
        <TimeNavigation
          cursor={new Date(`${startDate}T00:00:00`)}
          setDate={newDate => setCustomStartDate(format(newDate, "yyyy-MM-dd"))}
          unit={dateRange}
        />
      )}

      {dateRange === "custom" && (
        <div className="d-flex flex-column border p-3 mb-4">
          <div className="d-flex justify-content-center">
            <div className="d-flex filters col-md-9 align-items-center">
              <>
                <input
                  type="date"
                  className="form-control w-inherit"
                  value={customStartDate}
                  onChange={e => setCustomStartDate(e.target.value)}
                />
                <b className="mr-2 ml-2">{"to "}</b>
                <input
                  type="date"
                  className="form-control w-inherit"
                  value={customEndDate}
                  onChange={e => setCustomEndDate(e.target.value)}
                />
              </>
            </div>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-end">
        <div className="d-flex justify-content-between align-items-center mr-2">
          <div />
          <CSVLink
            data={csvData}
            filename="performance-profit-data.csv"
            className="btn btn-primary align-self-center"
          >
            Export to CSV
          </CSVLink>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div />
          <a
            href={"/forecast/sync"}
            className="btn btn-primary align-self-center"
          >
            Sync with Forecast
          </a>
        </div>
      </div>

      <div className="table-responsive-horizontal">
        <ForecastDataTable
          data={data}
          selectedProjects={selectedProjects}
          onCsvDataReady={handleCsvDataReady}
          businessDaysInPeriod={businessDaysInPeriod}
        />
      </div>
    </>
  )
}

export default ForecastDashboard
