import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@reach/disclosure"
import {
  addWeeks,
  format,
  getISOWeek,
  getISOWeekYear,
  isSameWeek,
  subWeeks,
  parseISO,
} from "date-fns"
import React, { useState } from "react"
import { AreaChart } from "react-chartkick"
import { useQuery } from "react-query"
import invariant from "tiny-invariant"
import { BooleanParam, DateParam, useQueryParam } from "use-query-params"
import { endOfWeek, formatDatePeriod, startOfWeek } from "../util/dates"
import { queryRequest } from "../util/request"
import { withAppContext } from "./AppContext"
import TimeNavigation from "./TimeNavigation"

const ProjectDashboard = () => {
  const [now] = useState(() => new Date())
  let [isStaleVisible, setIsStaleVisible] = useQueryParam("stale", BooleanParam)
  isStaleVisible = isStaleVisible ?? false

  let [cursor, setDate] = useQueryParam("when", DateParam)
  cursor = cursor ?? now

  const weekStart = startOfWeek(cursor)
  const weekEnd = endOfWeek(weekStart)

  const { data, status, error } = useQuery(
    [
      "projects",
      {
        path: "/projects.json",
        ...(!isStaleVisible && {
          params: { forecast_updated_min: subWeeks(now, 4) },
        }),
      },
    ],
    queryRequest,
    { retry: false },
  )

  const groupedByClient = data
    ? data.results.reduce((acc: any[], project: any) => {
        const { client, ...rest } = project
        const prev = acc.find(x => x.client.id === client.id)

        const entry = {
          client: prev ? prev.client : client,
          projects: [...(prev ? prev.projects : []), rest],
        }

        return prev
          ? acc.map(x => (x.client.id === client.id ? entry : x))
          : [...acc, entry]
      }, [])
    : null

  return (
    <div>
      <div className="mb-3 mb-4 sticky-top bg-white py-1 px-2 border-bottom">
        <Disclosure>
          <TimeNavigation
            cursor={cursor}
            setDate={setDate}
            now={now}
            unit="week"
          />

          <DisclosurePanel>
            <div className="form-check my-2">
              <input
                type="checkbox"
                id="stale"
                checked={isStaleVisible}
                onChange={e => setIsStaleVisible(e.currentTarget.checked)}
                className="form-check-input"
              />
              <label className="form-check-label" htmlFor="stale">
                Show stale projects (projects that haven't been updated in
                forecast for more than 4 weeks)
              </label>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>

      {status === "loading" ? (
        <div className="p-2">Loading...</div>
      ) : status === "error" ? (
        <div className="p-2">Error: {(error as Error).message}</div>
      ) : status === "success" && data ? (
        <div>
          {groupedByClient.map(({ client, projects }: any, index: number) => (
            <div
              key={client.id}
              style={{
                backgroundColor:
                  index % 2 === 0 ? undefined : "rgba(0, 0, 0, 0.03)",
              }}
              className="p-2"
            >
              {client.display_name}
              {projects.map((project: any) => (
                <Project key={project.id} project={project} date={cursor!} />
              ))}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}

const Project = ({ project, date }: { project: any; date: Date }) => {
  const { data, error } = useQuery(
    [
      "harvest_project",
      {
        path: `/harvest_projects/${
          project.harvest_project.id
        }.json?iso_year=${getISOWeekYear(date)}&iso_week=${getISOWeek(date)}`,
      },
    ],
    queryRequest,
    { retry: false },
  )

  const empty = [
    { name: "Loading...", data: {} },
    { name: "Loading...", data: {} },
  ]

  const yAxisDefaultMax = 15
  const isAboveMax = data
    ? (data.billable_spark_graph_data as any[]).some(({ data }) =>
        Object.values(data as { [x: string]: number }).some(
          value => value > yAxisDefaultMax,
        ),
      )
    : false

  return (
    <div className="d-lg-flex w-100 mb-2">
      <div className="pr-2 mb-1 w-100" style={{ maxWidth: 300 }}>
        <a href={`/projects/${project.id}`} title={project.description}>
          {project.description}
        </a>
        {data && data.forecast_id != null && (
          <div className="small">
            <a
              href={`https://forecastapp.com/548558/schedule/projects?filter=${encodeURI(
                data.forecast_name,
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted"
            >
              Forecast <i className="fa fa-external-link-alt" />
            </a>
            <a
              href={`https://tanookilabs.harvestapp.com/projects/${data.harvest_id}?period=week&tab=team`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-muted ml-2"
            >
              Harvest <i className="fa fa-external-link-alt" />
            </a>
          </div>
        )}
        <details>
          <summary className="small text-muted">Harvest Users</summary>
          {data
            ? data.harvest_users.map((user: any, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`user-${index}-${user.id}`}>
                  <a href={`/harvest_users/${user.id}`}>
                    {user.first_name} {user.last_name}
                  </a>
                </div>
              ))
            : "Loading..."}
        </details>
        {error && (
          <div className="alert alert-danger">{(error as Error).message}</div>
        )}
      </div>
      <AreaChart
        legend={false}
        data={data ? data.billable_spark_graph_data : empty}
        min={0}
        max={isAboveMax ? undefined : yAxisDefaultMax}
        lineTension={0}
        height={120}
      />

      <div>
        {data && (
          <>
            <WeeklyHours
              startsOn={parseISO(data.prevprev_starts_on)}
              endsOn={parseISO(data.prevprev_ends_on)}
              hoursSpent={data.prevprev_spent_hours}
              hoursBooked={data.prevprev_booked_hours}
            />
            <WeeklyHours
              startsOn={parseISO(data.prev_starts_on)}
              endsOn={parseISO(data.prev_ends_on)}
              hoursSpent={data.prev_spent_hours}
              hoursBooked={data.prev_booked_hours}
            />
            <WeeklyHours
              startsOn={parseISO(data.cursor_starts_on)}
              endsOn={parseISO(data.cursor_ends_on)}
              hoursSpent={data.cursor_spent_hours}
              hoursBooked={data.cursor_booked_hours}
            />
            <WeeklyHours
              startsOn={parseISO(data.next_starts_on)}
              endsOn={parseISO(data.next_ends_on)}
              hoursSpent={data.next_spent_hours}
              hoursBooked={data.next_booked_hours}
            />
          </>
        )}
      </div>
    </div>
  )
}

const WeeklyHours = ({
  hoursBooked,
  hoursSpent,
  startsOn,
  endsOn,
}: {
  hoursBooked: number
  hoursSpent: number
  startsOn: Date
  endsOn: Date
}) => {
  return (
    <div className="pl-3" style={{ minWidth: 300 }}>
      <HoursIndicator hoursSpent={hoursSpent} hoursBooked={hoursBooked}>
        <div className="d-flex p-1 text-white">
          <div className="small mr-auto">
            {hoursSpent} of {hoursBooked} Hours
          </div>
          <div className="small pl-2">{formatDatePeriod(startsOn, endsOn)}</div>
        </div>
      </HoursIndicator>
    </div>
  )
}

const HoursIndicator: React.FunctionComponent<{
  hoursSpent: number
  hoursBooked: number
  children?: React.ReactNode
}> = ({ hoursSpent, hoursBooked, children }) => {
  const total = Math.max(hoursBooked, hoursSpent)

  const safeHoursSpentRatio =
    total === 0 ? 0 : Math.min(hoursSpent, hoursBooked) / total
  const hoursSpentRatio = total === 0 ? 0 : hoursSpent / total
  const hoursBookedRatio = total === 0 ? 0 : hoursBooked / total

  return (
    <div
      className="position-relative w-100 mb-1"
      style={{
        height: 24,
        border: "1px solid black",
        backgroundColor: "#aaa",
      }}
    >
      <div
        style={{
          backgroundColor: "#e61e14", //ratioColor(hoursSpentRatio),
          width: `${hoursSpentRatio * 100}%`,
          top: 0,
          bottom: 0,
          position: "absolute",
        }}
      ></div>
      <div
        style={{
          backgroundColor: "#0078c9", //ratioColor(hoursSpentRatio),
          width: `${safeHoursSpentRatio * 100}%`,
          top: 0,
          bottom: 0,
          position: "absolute",
        }}
      ></div>
      {hoursSpent > hoursBooked && (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,.8)",
            position: "absolute",
            top: -3,
            bottom: -3,
            width: 2,
            borderRadius: 2,
            left: `${hoursBookedRatio * 100}%`,
          }}
        ></div>
      )}
      <div className="position-relative">{children}</div>
    </div>
  )
}
export default withAppContext(ProjectDashboard)
