import React from 'react'

const ForecastProjectFilter = ({ projects, onSelectionChange }) => {

  const sortedProjects = Object.entries(projects).sort(([, a], [, b]) => {
    return a.name.localeCompare(b.name)
  })

  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Filter Projects
      </button>
      <div className="dropdown-menu p-3" aria-labelledby="dropdownMenuButton" style={{ maxHeight: '400px', overflowY: 'auto', minWidth: '250px' }}>
        {sortedProjects.map(([projectId, projectDetails]) => {
          return (
            <div className="form-check" key={projectId}>
              <input
                type="checkbox"
                className="form-check-input"
                id={projectId}
                checked={projectDetails["selected"]}
                onChange={() => onSelectionChange(projectId)}
              />
              <label className="form-check-label" htmlFor={projectId}>
                {projectDetails["name"]}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ForecastProjectFilter
