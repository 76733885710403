import React from 'react'
import axios from 'axios'
import { useFormik } from 'formik'
import CurrencyInput from './CurrencyInput'
import Select from 'react-select'
import { format, startOfWeek, endOfWeek, subWeeks } from 'date-fns'

// Helper function to get the Monday of the previous week
const getPreviousWeekMonday = () => {
  const today = new Date()
  const lastWeek = subWeeks(today, 1)
  const lastWeekMonday = startOfWeek(lastWeek, { weekStartsOn: 1 })
  return format(lastWeekMonday, 'yyyy-MM-dd')
}

// Helper function to get the Sunday of the previous week
const getPreviousWeekSunday = () => {
  const today = new Date()
  const lastWeek = subWeeks(today, 1)
  const lastWeekSunday = endOfWeek(lastWeek, { weekStartsOn: 1 })
  return format(lastWeekSunday, 'yyyy-MM-dd')
}

function RevenueAdjustmentsForm({ adjustment, clients, projects }) {
  const formik = useFormik({
    initialValues: {
      id: adjustment.id || null,
      harvest_client_id: adjustment.harvest_client_id || "",
      harvest_project_id: adjustment.harvest_project_id || "",
      adjustment_type: adjustment.adjustment_type || "comp",
      amount: adjustment.amount || "",
      start_date: adjustment.start_date || getPreviousWeekMonday(),
      end_date: adjustment.end_date || getPreviousWeekSunday()
    },
    onSubmit: (values, { setSubmitting, setErrors }) => {
      // Custom error handling for the client/project fields
      if (!values.harvest_client_id || !values.harvest_project_id) {
        const errors = {}
        if (!values.harvest_client_id) errors.harvest_client_id = 'Client is required'
        if (!values.harvest_project_id) errors.harvest_project_id = 'Project is required'
        setErrors(errors)
        setSubmitting(false)
        return
      }

      let url = '/revenue_adjustments'
      const method = values.id ? 'put' : 'post'
      if (values.id) {
        url += `/${values.id}`
      }

      axios({
        method: method,
        url: url,
        data: values
      })
      .then(response => {
        console.log('Success:', response.data)
        window.location.href = "/revenue_adjustments"
      })
      .catch(error => {
        if (error.response) {
          setErrors(transformBackendErrors(error.response.data))
          console.error('Validation errors:', error.response.data)
        } else {
          console.error('An unexpected error occurred:', error)
        }
        setSubmitting(false)
      })
    },
  })

  // Function to transform backend errors to match Formik error format
  const transformBackendErrors = (errors) => {
    const formikErrors = {}
    Object.keys(errors).forEach(key => {
      // Assuming the backend errors are arrays of messages under each field
      formikErrors[key] = errors[key].join(', ')
    })
    return formikErrors
  }

  const handleClientChange = selectedOption => {
    formik.setFieldValue('harvest_client_id', selectedOption ? selectedOption.value : '')
    formik.setFieldValue('harvest_project_id', '') // Reset project when client changes
  }

  const handleProjectChange = selectedOption => {
    formik.setFieldValue('harvest_project_id', selectedOption ? selectedOption.value : '')
  }

  const clientOptions = clients.map(client => ({
    value: client.id,
    label: client.name
  }))

  let selectedClient = {}
  if (formik.values.harvest_client_id) {
    selectedClient = clients.find(client => client.id === parseInt(formik.values.harvest_client_id))
  }

  const projectOptions = projects
    .filter(project => project.external_harvest_client_id === parseInt(selectedClient.harvest_id))
    .map(project => ({
      value: project.id,
      label: project.name
    }))

  return (
    <form onSubmit={formik.handleSubmit}>

      <div className="form-group">
        <label>Select a Client</label>
        <Select
          name="harvest_client_id"
          options={clientOptions}
          onChange={handleClientChange}
          value={clientOptions.find(option => option.value === parseInt(formik.values.harvest_client_id))}
          classNamePrefix="react-select"
        />
        {formik.errors.harvest_client_id && <div className="text-danger">{formik.errors.harvest_client_id}</div>}
      </div>

      <div className="form-group">
        <label>Select a Project</label>
        <Select
          name="harvest_project_id"
          options={projectOptions}
          onChange={handleProjectChange}
          value={projectOptions.find(option => option.value === parseInt(formik.values.harvest_project_id))}
          classNamePrefix="react-select"
          isDisabled={!formik.values.harvest_client_id}
        />
        {formik.errors.harvest_project_id && <div className="text-danger">{formik.errors.harvest_project_id}</div>}
      </div>

      <div className="form-group">
        <label>Adjustment Type</label>
        <select
          name="adjustment_type"
          onChange={formik.handleChange}
          value={formik.values.adjustment_type}
          className="form-control"
        >
          <option value="comp">Comp</option>
          <option value="write_up">Write Up</option>
          <option value="deferred">Deferred</option>
        </select>
        {formik.errors.adjustment_type && <div className="text-danger">{formik.errors.adjustment_type}</div>}
      </div>

      <div className="form-group">
        <label>Amount</label>
        <CurrencyInput
          value={formik.values.amount}
          onChange={formik.handleChange('amount')}
          onBlur={formik.handleBlur('amount')}
        />
        {formik.errors.amount && <div className="text-danger">{formik.errors.amount}</div>}
      </div>

      <div className="form-group">
        <label>Start Date</label>
        <input
          type="date"
          name="start_date"
          onChange={formik.handleChange}
          value={formik.values.start_date}
          className="form-control"
        />
        {formik.errors.start_date && <div className="text-danger">{formik.errors.start_date}</div>}
      </div>

      <div className="form-group">
        <label>End Date</label>
        <input
          type="date"
          name="end_date"
          onChange={formik.handleChange}
          value={formik.values.end_date}
          className="form-control"
        />
        {formik.errors.end_date && <div className="text-danger">{formik.errors.end_date}</div>}
      </div>

      <button type="submit" className="btn btn-primary" disabled={formik.isSubmitting}>Submit</button>
    </form>
  )
}

export default RevenueAdjustmentsForm
