import React, { useState } from "react"
import { Link, useLocation } from 'react-router-dom'
import Select from "react-select"
import { format } from "date-fns"
import { useQuery } from "react-query"

const fetchInvoicesData = async (page, clientHarvestId, state, startDate, endDate, dateAttribute) => {
  const response = await fetch(
    `/invoices?page=${page}&client_harvest_id=${clientHarvestId || ""}&state=${state || ""}&start_date=${startDate || ""}&end_date=${endDate || ""}&date_attribute=${dateAttribute || ""}`, {
      headers: {
        Accept: "application/json",
      },
  })
  if (!response.ok) throw new Error("Network response was not ok")
  return response.json()
}

const InvoicesIndex = ({ clients, states }) => {
  const location = useLocation()
  const [page, setPage] = useState(location.state?.page || 1)
  const [selectedClient, setSelectedClient] = useState(location.state?.selectedClient || null)
  const [selectedState, setSelectedState] = useState(location.state?.selectedState || null)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [selectedDateAttribute, setSelectedDateAttribute] = useState("issue_date")

  const clientOptions = clients
    .map(client => ({ value: client.harvest_id, label: client.name }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const stateOptions = states.map(state => ({ value: state, label: state.charAt(0).toUpperCase() + state.slice(1) }))

  const dateAttributeOptions = [
    { value: "issue_date", label: "Issue Date" },
    { value: "due_date", label: "Due Date" },
    { value: "expected_date", label: "Expected Date" },
  ]

  const { data, isLoading, error } = useQuery(
    ["invoicesData", page, selectedClient?.value, selectedState?.value, startDate, endDate, selectedDateAttribute],
    () => fetchInvoicesData(page, selectedClient?.value, selectedState?.value, startDate, endDate, selectedDateAttribute),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 15, // 15 minutes
    },
  )

  if (isLoading || !data) return <div>Loading...</div>
  if (error) return <div>An error occurred: {error.toString()}</div>

  return (
    <>
      <h1>Invoices</h1>
      <div className="container mt-4">
        <div className="row mb-3">
          <div className="col-md-6">
            <Select
              options={clientOptions}
              value={selectedClient}
              onChange={setSelectedClient}
              placeholder="Select Client"
              isClearable
            />
          </div>
          <div className="col-md-6">
            <Select
              options={stateOptions}
              value={selectedState}
              onChange={setSelectedState}
              placeholder="Select State"
              isClearable
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Start Date"
            />
          </div>
          <div className="col-md-4">
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="End Date"
            />
          </div>
          <div className="col-md-4">
            <Select
              options={dateAttributeOptions}
              value={dateAttributeOptions.find(option => option.value === selectedDateAttribute)}
              onChange={(option) => setSelectedDateAttribute(option.value)}
              placeholder="Select Date Attribute"
            />
          </div>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Invoice Number</th>
              <th>Client Name</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Issue Date</th>
              <th>Due Date</th>
              <th>Expected Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>
                  <a href={`https://tanookilabs.harvestapp.com/invoices/${invoice.harvest_id}`} target="_blank" rel="noopener noreferrer">
                    {invoice.number}
                  </a>
                </td>
                <td>{invoice.harvest_client.name}</td>
                <td>{invoice.state}</td>
                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(invoice.amount)}</td>
                <td>{format(new Date(invoice.issue_date), "MM/dd/yyyy")}</td>
                <td>{invoice.due_date ? format(new Date(invoice.due_date), "MM/dd/yyyy") : "-"}</td>
                <td>{invoice.expected_date ? format(new Date(invoice.expected_date), "MM/dd/yyyy") : "-"}</td>
                <td>
                  <Link
                    className="btn btn-primary"
                    to={`/invoices/${invoice.id}`}
                    state={{ invoice, selectedClient, selectedState, page }}
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav>
          <ul className="pagination">
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => setPage(page - 1)} disabled={page === 1}>
                Previous
              </button>
            </li>
            <li className="page-item">
              <span className="page-link">{page}</span>
            </li>
            <li className={`page-item ${data.invoices.length === 0 ? "disabled" : ""}`}>
              <button className="page-link" onClick={() => setPage(page + 1)} disabled={data.invoices.length === 0}>
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default InvoicesIndex
