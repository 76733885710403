import {
  endOfWeek as dfnEndOfWeek,
  startOfWeek as dfnStartOfWeek,
  isSameMonth,
  format,
} from "date-fns"

const dateOpts = { weekStartsOn: 1 } as const

export const endOfWeek = (date: number | Date) => dfnEndOfWeek(date, dateOpts)
export const startOfWeek = (date: number | Date) =>
  dfnStartOfWeek(date, dateOpts)

export function formatDatePeriod(start: Date, end: Date) {
  if (isSameMonth(start, end)) {
    return format(start, "MMM d") + format(end, "–d")
  }
  return format(start, "MMM d") + format(end, "–MMM d")
}
